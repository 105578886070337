import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useWeightList() {
  // Use toast
  const toast = useToast();

  const refWeightListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "animalid",
      label: VueI18n.t("animal"),
    },
    {
      key: "chart",
      label: VueI18n.t("chart"),
    },

    {
      key: "earingno",
      label: VueI18n.t("earingNo"),
    },
    {
      key: "lastCondition",
      label: VueI18n.t("lastCondition"),
    },
    {
      key: "lastWeight",
      label: VueI18n.t("lastWeight"),
    },
    {
      key: "lastDate",
      label: VueI18n.t("lastDate"),
      formatter: (value) => {
        return dayjs(value).locale(VueI18n.locale).format("DD MMMM dddd YYYY");
      },
    },
    {
      key: "padokname",
      label: VueI18n.t("padokName"),
    },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const date = ref(dayjs().format("YYYY-MM-DD"));
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allWeight = ref([]);
  const filteredWeights = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredWeights.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.value
          : localItemsCount,
      of: allWeight.value.length,
    };
  });

  const refetchData = () => {
    if (date.value != dayjs().format("YYYY-MM-DD")) {
      fetchWeights();
    }
    if (searchQuery.value)
      filteredWeights.value = allWeight.value.filter((val) =>
        val.earingno.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    else filteredWeights.value = allWeight.value;
  };
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      date,
    ],
    () => {
      refetchData();
    }
  );
  const fetchWeights = async (ctx, callback) => {
    var weightg = await store
      .dispatch("animalsModule/fetchAnimalsWeights", {
        reportdate: date.value,
      })

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("weightgs") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allWeight.value = weightg;
    filteredWeights.value = [...weightg];
    return weightg;
  };

  const fetchConditions = async (ctx) => {
    var condition = await store
      .dispatch("animalsModule/fetchAnimalCondition", {
        animalid: ctx,
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("conditions") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return condition;
  };

  const deleteWeight = (ctx) => {
    store
      .dispatch("animalsModule/deleteWeight", ctx)
      .then((response) => {
        fetchWeights();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("weightg") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("weightg") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchWeights,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refWeightListTable,
    deleteWeight,
    allWeight,
    filteredWeights,
    date,
    refetchData,
    fetchConditions,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
