<template>
  <div>
    <animal-condition-chart
      :conditions="allConditions"
      :animal="selectedAnimal"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <label>{{ $t("eachPage") }}</label>
          <v-select
            v-model="perPage"
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label> {{ $t("piece") }} </label>
        </b-col>
        <!-- Table Top -->

        <!-- Search -->
        <b-col cols="12" md="6" class="mt-2 mt-sm-0">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              :placeholder="$t('search')"
            />
          </div>
        </b-col>
        <!-- Per Page -->
      </b-row>

      <b-table
        ref="refWeightListTable"
        class="position-relative"
        :items="filteredWeights"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(animalid)="data">
          <b-link
            :to="{
              name: 'animal-weights-conditions',
              query: { id: data.item.animalid },
            }"
          >
            {{ $t("animalWeight") }}
          </b-link>
        </template>
        <template #cell(chart)="data">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="openChart(data.item)"
          >
            <feather-icon icon="BarChart2Icon" />
          </b-button>
        </template>

        <template #cell(edit)="data">
          <b-link
            v-if="data.item.allowEdit == 1"
            @click="openEditSideBar(data)"
          >
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredWeights.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BButton,
} from "bootstrap-vue";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useWeightList from "./useWeightList";

import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import AnimalConditionChart from "@/views/components/AnimalConditionChart.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,

    vSelect,
    AnimalConditionChart,
    BButton,
    FeatherIcon,
  },
  data() {
    return {};
  },

  setup({ emit, vm }) {
    const isAddNewWeightSidebarActive = ref(false);
    const isEditWeightSidebarActive = ref(false);
    const selectedWeight = ref({});
    const userData = getUserData();
    const openEditSideBar = ({ item }) => {
      selectedWeight.value = item;
      isEditWeightSidebarActive.value = !isEditWeightSidebarActive.value;
    };
    var instance = getCurrentInstance();
    const allConditions = ref([]);
    const selectedAnimal = ref({ earingno: "" });
    const openChart = async (item) => {
      var conditions = await fetchConditions(item.animalid);
      selectedAnimal.value.earingno = item.earingno;
      allConditions.value = conditions;
      // animal-condition-chart modal open
      instance.proxy.$bvModal.show("animal-condition-chart");
    };

    const {
      fetchWeights,
      fetchConditions,
      tableColumns,
      perPage,
      currentPage,
      totalWeights,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWeightListTable,
      refetchData,
      filteredWeights,
      date,
      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteWeight,
    } = useWeightList();
    fetchWeights();
    return {
      // Sidebar
      isAddNewWeightSidebarActive,
      isEditWeightSidebarActive,
      openEditSideBar,
      allConditions,
      selectedAnimal,
      selectedWeight,
      openChart,
      filteredWeights,
      fetchWeights,
      tableColumns,
      perPage,
      currentPage,
      totalWeights,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWeightListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      date,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteWeight,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
